import React from "react";

const Logo = (props) => {
  return (
    <img
      src="images/company_logo.svg"
      alt="Logo"
      {...props}
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    />
  );
};

export default Logo;
