import React, { useContext, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";

// import { UserContext } from 'src/context/User'
import { useHistory, Link as RouterLink } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import Logo from "src/component/Logo";
import ApiConfig from "src/apiconfig/apiconfig";
import toast from "react-hot-toast";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";

const formValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Please enter a valid email."
    )
    .max(256, "Email should not exceed 256 characters.")
    .required("Email is required."),
});

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "50px 0px 30px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "rgba(255, 255, 255, 0.02)",
    borderTop: "1px solid rgba(0, 0, 0, 0.25)",
    "& .about": {
      color: theme.palette.text.primary,
      fontSize: "20px",
    },
    "& .container": {
      maxWidth: "1500px",
    },

    "& .footerContentBox": {
      "& h6, & p": {
        fontFamily: "poppins",
      },
      maxWidth: "340px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },

      "& .communityContainer": {
        marginTop: "17px",
      },
    },
    "& .copy": {
      fontSize: "12px",
      borderTop: "2px solid #D6D6D6",
      textAlign: "center",
      fontWeight: "300",
      paddingTop: "23px",
    },
    "& ul": {
      paddingLeft: "0",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        display: "block",
        "& svg": {
          marginRight: "10px",
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "rgba(255 255 255 / 30%)",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
    },
    "& h6": {
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },

    "& a": {
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      fontFamily: "'Calibri', sans-serif",
      color: "#484848",
      padding: "3px",
      paddingBottom: "10px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
      "&:hover": {
        color: "#EC1F24",
        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .textContainer": {
      marginTop: "69px",
      "& h4": {
        fontFamily: "Berlin Sans FB",
        fontSize: "24px",
        fontWeight: 400,
      },
      "& p": {
        color: theme.palette.text.light,
        marginTop: "11px",
        fontFamily: "poppins",
      },
    },

    "& .textfieldContainer": {
      marginTop: "20px",
      "& .MuiButton-containedPrimary": {
        fontSize: "14px",
      },
      "& .textfield": {
        "& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after":
          {
            borderBottom: "none",
          },
        "& .MuiFilledInput-input": {
          padding: "7px 12px 10px",
        },
        "& .MuiFilledInput-root": {
          height: "50px",
          background: "#ECECEC",
          borderRadius: "5px",
        },
        "& .MuiFilledInput-adornedEnd": {
          paddingRight: "0px",
          "& button": {
            padding: "0px",
            width: "100%",
            borderRadius: "0 5px 5px 0",
            height: "50px",
          },
        },

        "& .MuiInputAdornment-positionEnd": {
          width: "100%",
          maxWidth: "109px",
        },
      },
    },
  },
  iconbtn: {
    "& .MuiIconButton-root": {
      border: "0.5px solid #78819F",
      marginRight: "8px",
      marginBottom: "8px",
      borderRadious: "10px",
      borderRadius: "7px",
      width: "30px",
      height: "30px",
      padding: "0px",
      "& svg": {
        color: "#FF7F26",
        fontSize: "18px",
        "& :hover": {
          "& svg": {
            color: "#EC1F24",
            fontSize: "18px",
          },
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleAuthentication = () => {
    history.push("/login");
  };

  const handleFormSubmit = async (values, resetForm) => {
    try {
      setLoading(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.subscribe,
        data: {
          email: values.email,
        },
      });

      if (res?.data?.responseCode === 200) {
        setLoading(false);
        toast.success(res?.data?.responseMessage);
        resetForm();
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        toast.error(error?.response?.data?.responseMessage);
      }
    }
  };

  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg" className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Box style={{ width: "171px", height: "56px" }}>
              <Logo />
            </Box>
            <Box className="textContainer">
              <Typography variant="h4" color="textPrimary">
                Stay Updated
              </Typography>
              <Typography variant="body2" color="textLight">
                Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum
                dolor sit amet, consectetur adipiscing
              </Typography>
            </Box>

            <Formik
              initialValues={{
                email: "",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values, { resetForm }) =>
                handleFormSubmit(values, resetForm)
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                touched,
                values,
                handleSubmit,
                isValid,
                dirty,
                resetForm,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className="textfieldContainer">
                    <TextField
                      fullWidth
                      variant="filled"
                      placeholder="email@example.com"
                      className="textfield"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="containedPrimary"
                              type="submit"
                              disabled={!values.email}
                            >
                              Subscribe {loading && <ButtonCircularProgress />}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>

          <div style={{ border: "1px solid #D6D6D6", margin: "0 20px" }}></div>
          <Grid item xs={12} sm={12} md={5}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3} md={4}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className="about"
                  // sx={{fontWeight:800}}
                  style={{ fontWeight: "Bold" }}
                >
                  About Us
                </Typography>
                <List>
                  <ListItem to="/media-center" component={RouterLink}>
                    Podcasts
                  </ListItem>

                  <ListItem to="/services" component={RouterLink}>
                    Services
                  </ListItem>
                  <ListItem to="/subscription-plans" component={RouterLink}>
                    Pricing
                  </ListItem>
                  <ListItem to="/subscription-plans" component={RouterLink}>
                    Subscribe
                  </ListItem>

                  <ListItem to="/demo" component={RouterLink}>
                    Demo
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className="about"
                  style={{ fontWeight: "Bold" }}
                >
                  Resources
                </Typography>
                <List>
                  <ListItem to="/login" component={RouterLink}>
                    Media Distributor
                  </ListItem>
                  <ListItem to="/login" component={RouterLink}>
                    Service Consumer
                  </ListItem>

                  <ListItem to="/login" component={RouterLink}>
                    Brands
                  </ListItem>
                  <ListItem to="/login" component={RouterLink}>
                    Partner Organization
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} sm={3} md={4}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className="about"
                  style={{ fontWeight: "Bold" }}
                >
                  Multi-Media Studio
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ border: "1px solid #D6D6D6", margin: "0 20px" }}></div>

          <Grid item xs={12} sm={12} md={3}>
            <Box className="footerContentBox">
              <div className="footerInfo">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ fontWeight: "Bold" }}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#FF7F26", marginTop: "10px" }}
                >
                  INTEGRAL MEDIA LIMITED
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "#484848 !important",
                    marginTop: "9px",
                    maxWidth: "284px",
                  }}
                >
                  Jadala Place, 1st FloorNgong Lane, Off Ngong Road
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#407BFF", marginTop: "14px" }}
                >
                  connect@fululizo.com
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#484848", marginTop: "13px" }}
                >
                  Tel: +254 797 771771
                </Typography>
              </div>

              <div className="communityContainer">
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: "15px", fontWeight: "Bold" }}
                >
                  Community
                </Typography>

                <Box className={classes.iconbtn} display="flex">
                  <IconButton href="https://www.facebook.com/" target="_blank">
                    <FaFacebookF className={classes.socialIcon} />
                  </IconButton>
                  <IconButton
                    href="https://twitter.com/i/flow/login"
                    target="_blank"
                  >
                    <TwitterIcon className={classes.socialIcon} />
                  </IconButton>

                  <IconButton href="https://telegram.org/" target="_blank">
                    <FaTelegramPlane className={classes.socialIcon} />
                  </IconButton>

                  <IconButton href="https://www.youtube.com/" target="_blank">
                    <YouTubeIcon className={classes.socialIcon} />
                  </IconButton>

                  <IconButton
                    href=" https://www.instagram.com/"
                    target="_blank"
                  >
                    <InstagramIcon className={classes.socialIcon} />
                  </IconButton>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Box className="copy displaySpacebetween" mt={4}>
          <Box>
            <Typography
              variant="body1"
              style={{ fontWeight: "400", color: "#484848" }}
            >
              © 2024 Fululizo Podcast | Integral Media Limited
            </Typography>
          </Box>
          <Box className="alignCenter" style={{ gap: "20px" }}>
            <Typography
              component={RouterLink}
              variant="body1"
              style={{ fontWeight: "400", color: "#484848", padding: "0" }}
              to="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </Typography>
            <Typography
              component={RouterLink}
              variant="body1"
              style={{ fontWeight: "400", color: "#484848", padding: "0" }}
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
