import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@material-ui/icons/Close";
import ApiConfig from "src/apiconfig/apiconfig";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Paper,
  ListItem,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import {
  sections,
  sections1,
  sections2,
  sections3,
  sections4,
  sections5,
  sections6,
  sections7,
} from "./SidebarTabs";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#fff",
  },
  sectionsHeading: {
    padding: "2px 0px 0px 22px",
    lineHeight: "normal",
    color: "#7C7C7C",
    fontSize: "12px",
  },
  desktopDrawer: {
    top: "100px",
    width: "300px",
    height: "calc(100% - 115px)",
    // marginTop: "6px",
    background: "#fff",
    borderRight: "1.17px solid #E8E8E8",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "& MuiCollapse-root": {
      "& .MuiList-root": {
        "& a": {
          color: "blue",
        },
      },
    },
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
    color: "#EC1F24",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  DialogMan: {
    "& .MuiDialog-paperWidthXs": {
      maxWidth: "560px !important",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 39px",
    },

    "& .icon": {
      color: "#fff",
      alignItems: "end",
      paddingRight: "25px",
      position: "absolute",
      top: "0px",
      right: "-10px",
    },
    "& .titleBox": {
      flex: "1",
      textAlign: "center",
      padding: "18px 0px",
      // width: "100%",
    },
    "& .MuiDialogTitle-root ": {
      background: "#767676 !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px ",
      "& .MuiTypography-h6": {
        width: "100%",
      },
    },
    "& .MuiTypography-h5": {
      color: "#fff",
    },
    "& .MuiTypography-body2": {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Open Sans",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      padding: "8px 39px",
    },
    "& .imgBox": {
      paddingTop: "32px",
    },
    "& .img": {
      paddingBottom: "27px",
    },
    "& .actionBtn": {
      padding: "32px 0px",
      [theme.breakpoints.up("xs")]: {
        "& .MuiButton-contained ": {
          fontSize: "18px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& .displayCenter": {
          flexDirection: "column",
        },
      },
      [theme.breakpoints.up("xs")]: {
        "& .btn": {
          padding: "8px 21px",
          width: "186px",
          height: "44px",
        },
      },
    },
  },

  logoutBtnList: {
    cursor: "pointer",
    "& .logoutBtn": { marginLeft: "30px" },
    "& .logOutIcon": { marginRight: "8px", fontSize: "20px" },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      "& .logoutBtn": {
        color: "#e35f24",
      },
    },
  },
}));

export default function NavBar({ onMobileClose, openMobile }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const userId = localStorage.getItem("userId");
  let userType = auth.userData?.userType;
  // setUserId(auth?.userData)
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  const [type, setType] = useState("yes");
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const [lastActiveTab, setLastActiveTab] = useState("");

  //filtered section5 array if the userType is INDIVIDUAL
  const filteredSections5 = sections5.map((section) => {
    const filteredSection = { ...section };
    filteredSection.items = section.items.filter((item) => {
      return userType === "INDIVIDUAL"
        ? item.title !== "AFFILIATED MEMBERS"
        : true;
    });

    return filteredSection;
  });

  const handleClose = () => {
    setIsLogout(false);
  };

  const handleLogoutClick = () => {
    setIsLogout(true);
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrentRoute(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const [activePlanData, setActivePlanData] = useState(null);
  console.log("activePlanDataactivePlanData", activePlanData);
  const getActivePlan = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${ApiConfig.viewSubscription}/${userId}`,
      });
      if (res?.data?.responseCode === 200) {
        setActivePlanData(res?.data?.result);
        console.log(res?.data?.result);
      }
    } catch (error) {
      if (error.response) {
        console.log(
          "error in active plan",
          error?.response?.data?.responseMessage
        );
      }
    }
  };
  // console.log(userId);

  useEffect(() => {
    getActivePlan();
  }, []);
  const sectionArray = [{ sections: sections, heading: null }];
  const distributeMedia = activePlanData?.functions?.distributeMedia;
  const createEditMedia = activePlanData?.functions?.createEditMedia;
  const multiUserFunction = activePlanData?.functions?.multiUserFunction;
  const trainerTools = activePlanData?.functions?.trainerTools;
  const studioTools = activePlanData?.functions?.studioTools;
  if (distributeMedia) {
    sectionArray.push({
      sections: sections1,
      heading: "Distribute Media",
    });
  }
  if (createEditMedia) {
    sectionArray.push({ sections: sections2, heading: "Create & Edit Media" });
  }
  if (trainerTools) {
    sectionArray.push({ sections: sections6, heading: "Trainer Tools" });
  }
  if (studioTools) {
    sectionArray.push({ sections: sections7, heading: "Studio Tools" });
  }
  if (multiUserFunction) {
    sectionArray.push({ sections: sections3, heading: "Multi user function" });
  }

  sectionArray.push({ sections: sections4, heading: "Transactions" });
  sectionArray.push({
    sections: userType === "ORGANIZATION" ? sections5 : filteredSections5,
    heading: "My Account",
  });

  //function to render content
  function renderContent() {
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box pb={2}>
            <Box
              className="sideMenuBox"
              style={{
                boxShadow:
                  "0px 23.306px 58.264px 0px rgba(220, 224, 249, 0.50)",
                paddingTop: "16px",
              }}
            >
              {sectionArray.map((category, categoryIndex) => (
                <React.Fragment key={`category${categoryIndex}`}>
                  <Typography className={classes.sectionsHeading}>
                    {category.heading}
                  </Typography>
                  {renderNavItems(category.sections[0]?.items || [])}
                </React.Fragment>
              ))}
            </Box>
          </Box>

          {isLogout && renderLogoutDialog()}
        </PerfectScrollbar>
      </Box>
    );
  }

  //function to render the logout dialog
  function renderLogoutDialog() {
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.DialogMan}
        open={isLogout}
        onClose={() => setIsLogout(false)}
      >
        <DialogTitle id="form-dialog-title">
          <Box className="displaySpacebetween">
            <Box className="titleBox">
              <Typography variant="h5">Logout</Typography>
            </Box>
          </Box>
          <IconButton className="icon" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box align="center" className="imgBox">
            <img src="images/Logout.svg" className="img" />
            <Typography variant="body2">
              Are you sure, you want to logout?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className="actionBtn">
          <Box mt={2} mb={2} className="displayCenter">
            <Button
              color={type === "no" ? "primary" : "secondary"}
              variant="contained"
              className="btn"
              onClick={() => {
                setType("no");
                setIsLogout(false);
              }}
            >
              No
            </Button>
            &nbsp; &nbsp;
            <Button
              color={type === "yes" ? "primary" : "secondary"}
              variant="contained"
              className="btn"
              onClick={() => {
                setType("yes");
                window.localStorage.removeItem("userToken");
                window.localStorage.removeItem("userId");
                window.localStorage.removeItem("activeStep");
                auth.userLogIn(false, null);
                history.push("/");
                window.location.reload();
              }}
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  //function to render navbar items
  function renderNavItems(items) {
    return (
      <List disablePadding>
        {items.map((item, index) => (
          <NavItem
            key={index}
            depth={0}
            href={item.href}
            icon={item.icon}
            info={item.info}
            title={item.title}
            handleLogoutClick={handleLogoutClick}
            pathname={location.pathname}
            isActive={isActiveTab(item)}
            subRoutes={item.subRoutes || []}
            setLastActiveTab={setLastActiveTab}
          />
        ))}
      </List>
    );
  }

  function isActiveTab(item) {
    const isActive =
      currentRoute === item.href ||
      item.subRoutes?.some((subRoute) => currentRoute === subRoute.href);

    if (!isActive && item.subRoutes) {
      const isSubrouteActive = item.subRoutes.some(
        (subRoute) => currentRoute === subRoute.href
      );
      return isSubrouteActive && lastActiveTab === item.href;
    }
    return isActive;
  }

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box>{renderContent()}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {renderContent()}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};
