import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "../src/scss/main.css";
import { Provider } from "react-redux";
import store from "./store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import "./firebase";


// const stripePromise = loadStripe("pk_test_51Op5yOSHlR3cZOVHsnA1ghaOuoJll8ihkBC8TZuXZKH7QaC3wzoxwpcJXetUbF5dApHxo8PWGVRU7byt8xUbKM3j000MbX0kte");
const stripePromise = loadStripe("pk_test_51Oln9rSIOsaYcGDDcQeYWukKKkXZS2X98AYfmHnrspkOoyf6qVwkEdKeBknFFWTqPjGogShdMcVeIrCSDbLevNXz007u9hiQSs");


ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
