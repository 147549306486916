import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
// import SettingsContext from "src/context/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  root: {
    backgroundColor: "#F8FAFB",
    // overflow: "hidden",
    position: "relative",
    height: "100vh",
  },

  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
    paddingTop: 96,
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 300,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },

  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: "#FAFAFA",
  },

  dashboardWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#F8FAFB",
    paddingTop: 100,
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 300,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },

  dashboardContent: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    // padding: "28px 25px 25px ",
    [theme.breakpoints.down("md")]: {
      padding: "25px 10px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px ",
    },
  },

  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    padding: "28px 25px 25px ",
    [theme.breakpoints.down("md")]: {
      padding: "30px 10px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "45px 10px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  // const themeSeeting = useContext(SettingsContext);

  const dashboardRoute = window.location.pathname === "/dashboard";

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div
            className={
              dashboardRoute ? classes.dashboardContent : classes.content
            }
            id="main-scroll"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
