import React, { Suspense, Fragment, useState, useEffect, useRef } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import { Toaster } from "react-hot-toast";
import Notification from "./Notification";
import NitificationPopUp from "src/component/NotificationAlertPopUp";
import NotificationAllow from "src/component/NotificationAllow";
import ReactDOM from "react-dom";
import UploadImageContext from "./context/UploadImage";
import { onMessageListener } from "./firebase";
const history = createBrowserHistory();

function App() {
  const theme = createTheme();
  console.log(theme);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState();
  const [notificationData, setNotificationData] = useState();
  const myContainer = useRef(null);
  const [isNotificationErr, setNotificationErr] = useState(false);

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("🚀 ~ .then ~ payload:", payload);
  //     if (window.localStorage.getItem("userToken")) {
  //       setOpen(true);
  //       setNotification(payload.notification);
  //       setNotificationData(payload.data);
  //     }
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // useEffect(() => {
  //   if (isNotificationErr) {
  //     ReactDOM.render(
  //       <>
  //         <NotificationAllow />
  //       </>,
  //       document.getElementById("rootid")
  //     );
  //   }
  // }, [isNotificationErr]);

  return (
    <div className="App">
      <Toaster position="top-right" />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Router history={history}>
              <Notification />
              <div ref={myContainer} id="rootid"></div>
              {/* <NitificationPopUp
                data={notificationData}
                notification={notification}
                open={open}
                setOpen={setOpen}
              /> */}
              <UploadImageContext>
                <RenderRoutes data={routes} />
              </UploadImageContext>
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
