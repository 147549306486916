const url = "https://nodepune-experienced.mobiloitte.io"; // staging url
// const url = "http://172.16.6.113:2020"; //  rishab url
// const url = "http://172.16.6.45:2020"; //  deepanshu url

export const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;
const serviceCategory = `${url}/api/v1/serviceCategory`;
const media = `${url}/api/v1/media`;
const user = `${url}/api/v1/user`;
const admin = `${url}/api/v1/admin`;
const theme = `${url}/api/v1/theme`;
const category = `${url}/api/v1/category`;
const advertisement = `${url}/api/v1/advertisement`;
const staticContent = `${url}/api/v1/static/viewStaticContent`;
const contact = `${url}/api/v1/contact`;
const demo = `${url}/api/v1/demo`;
const commission = `${url}/api/v1/commission`;
const project = `${url}/api/v1/project`;
const socket = `${url}/api/v1/socket`;
const mediaRating = `${url}/api/v1/mediaRating`;
const stripe = `${url}/api/v1/stripe`;
const serviceProvider = `${url}/api/v1/serviceProvider`;
const job = `${url}/api/v1/job`;
const milestone = `${url}/api/v1/milestone`;
const dispute = `${url}/api/v1/dispute`;
const notification = `${url}/api/v1/notification`;
const education = `${url}/api/v1/education`;

const ApiConfig = {
  login: `${user}/userLogin`,
  signup: `${user}/userSignUp`,
  signupStep2: `${user}/updateStep2`,
  signupStep3: `${user}/updateStep3`,
  verify: `${user}/verifyOTP`,
  forgot: `${user}/forgotPassword`,
  resetPassword: `${user}/resetPassword`,
  changePassword: `${admin}/changePassword`,
  resend: `${user}/resendotp`,
  uploadFiles: `${user}/uploadFiles`,
  listAffilate: `${user}/listAffilate`,
  getCommisionAmount: `${user}/getCommisionAmount`,
  listAffiliateMembers: `${user}/listMember`,
  viewAffiliateMember: `${user}/viewMemberProfile`,
  updateAffiliateMember: `${user}/updateMember`,
  addAffiliateMember: `${url}/api/v1/member/addMember`,
  deleteAffiliatedMember: `${user}/deleteMember`,

  transactionList: `${user}/transactionList`,
  getTransaction: `${user}/getTransaction`,
  // createrList: `${user}/listCreator`,
  // /user/creatorsList
  OrganizationWorkGraph: `${user}/individualVsOrganizationWorkGraph`,
  mediaAnalysisGraph: `${user}/mediaAnalysisGraph`,
  mediaDownloadsGraph: `${user}/mediaDownloadsGraph`,
  addToWatchLater: `${user}/addToWatchLater`,
  watchLaterList: `${user}/watchLaterList`,

  //education
  getAllEducation: `${education}/getAllEducation`,
  getAllEducationLevel: `${education}/getAllEducationLevel`,

  //plans
  getPlans: `${url}/api/v1/admin/planList`,
  buyPlan: `${user}/buyPlan`,

  //Creator
  getCreatorList: `${user}/creatorsList`,

  //media
  categoryList: `${category}/categoryCenterList`,
  theme: `${theme}/listTheme`,
  topic: `${theme}/listTopic`,
  addMedia: `${media}/createMedia`,
  getAllMedia: `${media}/mediaListAll`,
  viewMedia: `${media}/viewMedia`,
  viewMediaComments: `${media}/viewMediaComments`,
  mediaList: `${media}/approvedMediaList`,
  downloadMediaPayment: `${media}/downloadMediaPayment`,
  isMediaAllow: `${media}/isMediaAllow`,

  getProfile: `${user}/getProfile`,
  addProfilePic: `${user}/uploadFiles`,
  editUserProfile: `${user}/editUserProfile`,
  blockMedia: `${media}/blockUnblockMedia`,
  viewmediaContract: `${media}/viewmediaContract`,
  viewmedia: `${media}/viewmedia`,
  eSign: `${media}/Esign`,
  likeUnlikemedia: `${media}/likeUnlikemedia`,
  commentMedia: `${media}/commentMedia`,
  dashboardMedia: `${media}/dashboardMedia`,
  ContractapproveOrRejectMedia: `${media}/ContractapproveOrReject`,

  //service
  listServiceCategory: `${serviceCategory}/listServiceCategory`,
  editmedia: `${media}/editMedia`,
  deleteMedia: `${media}/deleteMedia`,

  //advertisement
  // getAllAdvertisement: `${advertisement}/listAdvertiseRequest`,
  getAllAdvertisement: `${advertisement}/listAdvertise`,
  addAdvertisement: `${advertisement}/addAdvertisement`,
  viewAdvertisement: `${advertisement}/viewAdvertiseDetail`,
  editAdvertisement: `${advertisement}/editAdvertisement`,
  deleteAdvertisement: `${advertisement}/deleteAdvertisement`,
  blockAdvertisement: `${advertisement}/blockUnblockAdvertisement`,
  viewAdvertisementContract: `${advertisement}/viewadvertisementContract`,
  ContractapproveOrReject: `${advertisement}/ContractapproveOrReject`,
  landingPageAdvertisement: `${advertisement}/listAdvertiseLandingPage`,
  advertisementPayment: `${advertisement}/advertisementPayment`,
  listAdvertiseByType: `${advertisement}/listAdvertiseByType`,

  //static content
  staticContent: `${staticContent}`,

  //contact
  contactUs: `${contact}/createContactUs`,

  //demo
  getDemo: `${demo}/getDemo`,

  //commision
  listCommission: `${commission}/listCommission`,
  userCommission: `${commission}/userCommission`,

  teamList: `${url}/api/v1/static/teamContentList`,

  //ProjectList
  projectList: `${project}/projectList`,
  createProject: `${project}/createproject`,
  viewProject: `${project}/viewproject`,
  deleteProject: `${project}/deleteProject`,
  editProject: `${project}/editProject`,
  ProjectApproveOrReject: `${project}/approveOrReject`,
  confirmProject: `${project}/confirmProject`,
  myProjectList: `${project}/projectList`,
  serviceProviderAcceptOrReject: `${project}/serviceProviderAcceptOrReject`,
  serviceProviderMarkDone: `${project}/serviceProviderMarkDone`,
  projectsendOTP: `${project}/projectsendOTP`,
  verifyOTP: `${project}/verifyOTP`,

  //My Project offered List

  contractProjectList: `${project}/contractProjectList`,
  viewprojectContract: `${project}/viewprojectContract`,
  approveOrRejectContract: `${project}/approveOrRejectContract`,
  markDoneProjectContract: `${project}/markDone`,
  listProjectMilestones: `${project}/listProjectMilestones`,
  listProjectMilestones: `${project}/listProjectMilestones`,

  //banner
  getAllBanner: `${url}/api/v1/banner/get-all-banners`,

  //socket
  oneToOneChatApi: `${socket}/oneToOneChatApi`,
  listUser: `${socket}/listUser`,
  createGroup: `${socket}/createGroup`,
  oneGroupToOneChatApi: `${socket}/oneGroupToOneChatApi`,
  agoraTokenGenerator: `${socket}/agoraTokenGenerator`,
  clearChat: `${socket}/clearChat`,
  callUser: `${socket}/callUser`,
  groupCallUser: `${socket}/groupCallUser`,

  //media rating
  rateMedia: `${mediaRating}/createmediaRating`,

  //affiliate
  affiliate: `${url}/api/v1/affiliate/affiliate`,
  blockUnblockAffiliate: `${url}/api/v1/admin/blockUnblockUser`,

  //stripe
  checkout: `${stripe}/checkout`,
  createPaymentIntent: `${stripe}/createPaymentIntent`,

  //service provider
  serviceProvideList: `${serviceProvider}/listServiceProvider`,
  viewServiceProvider: `${serviceProvider}/viewserviceProvider`,

  //job
  createjob: `${job}/createjob`,
  jobServiceList: `${job}/jobServiceList`,
  viewJob: `${job}/viewJob`,
  jobList: `${job}/jobList`,
  jobListView: `${job}/jobListView`,
  approveOrReject: `${job}/approveOrReject`,
  jobContractCreates: `${job}/jobContractCreates`,
  viewContract: `${job}/viewjobContract`,
  confirmjob: `${job}/confirmjob`,
  ContractapproveOrRejectJob: `${job}/ContractapproveOrReject`,
  markDone: `${job}/markDone`,
  markDoneCreator: `${job}/markDoneCreator`,
  jobDetailsWithContract: `${job}/jobDetailsWithContract`,

  // Dispute for mileStone
  createDispute: `${dispute}/createDispute`,

  //MileStones
  createMilestone: `${milestone}/createMilestone`,
  listAllMilestone: `${milestone}/listAllMilestone`,
  milestonePayment: `${milestone}/milestonePayment`,
  submitWork: `${milestone}/submitWork`,
  commentMilestone: `${milestone}/commentMilestone`,
  sendPaymentRequest: `${milestone}/sendPaymentRequest`,
  recievedPayment: `${url}/api/v1/payment/recievedPayment`,

  //viewsubscription
  viewSubscription: `${url}/api/v1/admin/viewsubscription`,

  // Notification
  listNotification: `${notification}/listNotification`,
  allNotificationsCount: `${notification}/newNotification`,
  viewNotification: `${notification}/viewNotification`,
  readNotification: `${notification}/readNotification`,
  readSingleNotification: `${notification}/readSingleNotification`,
  deleteNotification: `${notification}/deleteNotification`,
  clearAllNotification: `${notification}/clearAllNotification`,

  //for Payment of Wallet
  withdrawRequest: `${url}/api/v1/payment/withdrawRequest`,
  getwithdrawRequest: `${url}/api/v1/payment/getwithdrawRequest`,

  //news letter
  subscribe: `${url}/api/v1/subscriber/user-subscribe`,

  // Feedback

  feedbackList: `${url}/api/v1/feedback/feedbackList`,
  createFeedback: `${url}/api/v1/feedback/createFeedback`,
  findRating: `${url}/api/v1/feedback/findRating`,
  findRatingByProjectId: `${url}/api/v1/feedback/findRatingByProjectId`,

  // REVENUE & COMMISION
  revenueData: `${milestone}/revenueData`,
};

export default ApiConfig;
