import React, { useEffect, useState } from "react";
import { NavLink as RouterLink, matchPath, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    textTransform: "uppercase",
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    textTransform: "uppercase",
    padding: "13px 10px 5px 38px",
    justifyContent: "flex-start",
    marginBottom: "8px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",

    "&:hover": {
      color: theme.palette.text.secondary,
      background: "#F8F8F8",
      borderRadius: "0px",
      "& $icon": {
        color: "#262626",
      },
    },
  },
  buttonLeaf: {
    color: "#262626",
    fontWeight: 700,
    padding: "13px 5px 5px 38px",
    height: "60px",
    justifyContent: "flex-start",
    fontFamily: "poppins",
    textTransform: "uppercase",
    letterSpacing: 0,
    width: "100%",
    opacity: 1,
    fontSize: "16px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "& $icon": {
      color: "#262626",
    },
    "&:hover": {
      color: theme.palette.text.secondary,
      background: "#F8F8F8",
      borderRadius: "0px",
      "& $icon": {
        color: theme.palette.text.secondary,
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 400,
        fontSize: "16px",
        whiteSpace: "pre",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: "19px",
    color: "#262626",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
  },
  title: {
    marginRight: "auto",
    // color:theme.palette.text.primary,
    // fontSize:"14px",
    // fontWeight:400,
    // lineHeight:"35px",
    // textTransform:"capitalize",
  },
  active: {
    color: "#FF7F26",
    textTransform: "uppercase",
    fontWeight: 700,
    background: "#F8F8F8",
    // borderLeft: "6px solid #FF7F26",
    padding: "13px 5px 5px 32px",
    borderRadius: "0px",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      width: "6px", // Width of the border
      borderRadius: "0px 9.322px 9.322px 0px",
      backgroundColor: "#FF7F26", // Color of the border
    },
    "& $icon": {
      color: "#FF7F26",
      "&:hover": {
        color: "#000",
      },
    },
  },
}));

export default function NavItem({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  handleLogoutClick,
  pathname,
  isActive,
  subRoutes,
  setLastActiveTab,
  ...rest
}) {
  const classes = useStyles();

  console.log("isActive",isActive)
  
  return (
    <>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={isActive ? classes.active : ""}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact={!subRoutes || subRoutes.length === 0}
          to={title !== "LOGOUT" && href}
          // onClick={() => title === "LOGOUT" && handleLogoutClick()}
          onClick={() => {
            if (title !== "LOGOUT") {
              setLastActiveTab(href); 
            }
            title === "LOGOUT" && handleLogoutClick();
          }}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {Info && <Info />}
        </Button>
      </ListItem>
    </>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};
