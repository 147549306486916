import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "./firebase";

import { makeStyles, Box, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory } from "react-router-dom";
import { localeFunction } from "src/utils/index";

import NitificationPopUp from "src/component/NotificationAlertPopUp";

const useStyles = makeStyles((theme) => ({
  acceptBtn: {
    fontSize: "14px",
    color: "green",
  },
  rejectBtn: {
    fontSize: "14px",
    color: "red",
  },
}));

const Notification = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [notificationData, setNotificationData] = useState();

  const notify = () => {
    return <ToastDisplay />;
  };

  const msgDetails = {
    data: {
      callType: "Voice",
      channelId: "6prdja2qo93g17ta2hseif30sjvye0",
      name: "aniket123",
      profilePic:
        "https://res.cloudinary.com/da9ikitxs/image/upload/v1708668505/m5xbjxzw0cxlswcph9j5.jpg",
      title: "Hokage@7 Calling...",
      token:
        "006d447e6c2ca2648718b0e4736ef732a8cIADKp3h7xmdSUqH+zylzOqUBOucfeuRNtq9ki7fXau/dWj2E1SMAAAAAIgD2lTcZsUX1ZQQAAQBBAvRlAgBBAvRlAwBBAvRlBABBAvRl",
    },
    from: "1019698100663",
    messageId: "18d76996-a4b8-4421-99a5-28112b23977b",
    notification: {
      body: "aniket123 is calling...",
      title: "Voice Calling...",
    },
  };

  function ToastDisplay() {
    return (
      <>
        <NitificationPopUp
          data={notificationData}
          notification={notification}
          open={open}
          setOpen={setOpen}
        />
      </>
    );
  }

  // useEffect(() => {
  //   setNotificationData(msgDetails.data);
  //   setNotification(msgDetails.notification);
  //   setOpen(true);
  //   notify();
  // }, []);

  useEffect(() => {
    if (notification?.title) {
      ToastDisplay();
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ .then ~ payload:", payload);
      // console.log("🚀 ~ .then ~ payload11111:", payload);
      setOpen(true);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      setNotificationData(payload?.data);

      console.log(
        "🚀 ~ Notification ~ notificationDatainNotification:",
        notificationData
      );
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <NitificationPopUp
      data={notificationData}
      notification={notification}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default Notification;
