import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Menu,
  Paper,
  Dialog,
  Avatar,
  Typography,
  Hidden,
  SvgIcon,
  Popover,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "src/component/Logo";
import { NavLink } from "react-router-dom";
import { UserContext } from "src/context/User";
import { AuthContext } from "src/context/Auth";
import { FaBell } from "react-icons/fa";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Notification from "src/views/pages/Notifications/Notification";

const headersData = [
  {
    label: "About Us",
    href: "/about",
  },
  {
    label: "Media Center",
    href: "/media-center",
  },
  {
    label: "Services",
    href: "/services",
  },

  {
    label: "Creators",
    href: "/creators",
  },

  {
    label: "Charges",
    href: "/charges",
  },

  {
    label: "Subscribe",
    href: "/subscription-plans",
  },

  {
    label: "Demo",
    href: "/demo",
  },

  {
    label: "Team",
    href: "/team",
  },

  {
    label: "Contact Us",
    href: "/contact-us",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    borderRadius: 0,
    minWidth: "auto",
    color: "#262626",
    // padding: "0px 20px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#EC1F24",
    },
    "&:hover": {
      color: "#EC1F24",
    },
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    // padding: " 5px 0px",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "140px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#ffffff",
    color: "#262626",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  menuMobile: {
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "10px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",
    marginLeft: 20,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    borderRadius: " 50px",
  },
  menuMobile1: {
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.background.dark,
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "300",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#ff3965",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#000",

      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
  afterLogin: {
    display: "flex",
    alignItems: "center",
    gap: "24px",

    "& .MuiIconButton-root": {
      padding: "0",
      color: theme.palette.text.primary,
    },

    "& p": {
      cursor: "pointer",
    },
  },

  popoverContent: {
    width: "200px",
    height: "auto",
    boxSizing: "border-box",
    padding: "23px 30px 23px 16px",

    "& .icon-and-name": {
      display: "flex",
      alignItems: "center",
      gap: "16px",

      "& p": {
        lineHeight: "35px",
        whiteSpace: "nowrap",
      },
    },
  },
}));

export default function TopBar({ className, onMobileNavOpen, ...rest }) {
  const history = useHistory();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const user = useContext(UserContext);
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose4 = () => {
    setAnchorEl1(null);
  };
  const [countNotification, setCountNotification] = useState(0);
  const handleNotificationClick = () => {
    setDialogOpen(true);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    mainHeader,
    afterLogin,
    popoverContent,
  } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const [organization, SetOrganization] = useState(false);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>

        {auth?.userLoggedIn && auth?.userData && (
          <Box>
            <Typography
              variant="body2"
              style={{
                color: "#262626",
                fontFamily: "Open Sans",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Welcome !{" "}
              {auth?.userData?.firstName &&
              auth?.userData?.firstName.length > 10
                ? auth?.userData?.firstName.slice(0, 10)
                : auth?.userData?.firstName}
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ gap: "24px" }}
        >
          {getMenuButtons()}

          {auth.userLoggedIn && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={afterLogin}
            >
              <IconButton
                style={{
                  marginRight: "24px",
                  marginLeft: "16px",
                  background: "#0000000D",
                  position: "relative",
                }}
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={countNotification} color="error">
                  <FaBell />
                </Badge>
              </IconButton>
              <Notification
                setCountNotification={setCountNotification}
                open={dialogOpen}
                setOpen={setDialogOpen}
              />
              <Typography
                variant="body1"
                className={classes.menuButton}
                onClick={() => history.push("/dashboard")}
              >
                Dashboard
              </Typography>

              <PopupState
                variant="popover"
                popupId="popup-popover0"
                className={classes.popover}
              >
                {(popupState) => (
                  <div style={{ cursor: "pointer" }}>
                    <div {...bindTrigger(popupState)}>
                      {auth.userData?.profilePic ? (
                        <img
                          src={auth.userData?.profilePic}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Avatar />
                      )}
                    </div>

                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className={classes.popoverContent}>
                        <Box className="icon-and-name">
                          <img src="images/profile_icon.svg" />
                          <Typography
                            variant="body2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push("/view-profile");
                            }}
                          >
                            View Profile
                          </Typography>
                        </Box>

                        {auth?.userData?.userType == "ORGANIZATION" && (
                          <Box className="icon-and-name">
                            <img src="images/affiliatedmember_icon.svg" />
                            <Typography
                              style={{ cursor: "pointer" }}
                              variant="body2"
                              onClick={() => {
                                history.push("/affiliatedmembers");
                              }}
                            >
                              Affiliated Member
                            </Typography>
                          </Box>
                        )}

                        <Box className="icon-and-name">
                          <img src="images/reward_icon.svg" />
                          <Typography
                            style={{ cursor: "pointer" }}
                            variant="body2"
                            onClick={() => {
                              history.push("/rewards");
                            }}
                          >
                            Reward
                          </Typography>
                        </Box>

                        <Box className="icon-and-name">
                          <img src="images/settings_icon.svg" />
                          <Typography
                            style={{ cursor: "pointer" }}
                            variant="body2"
                            onClick={() => {
                              history.push("/change-password");
                            }}
                          >
                            Settings
                          </Typography>
                        </Box>

                        <Box className="icon-and-name">
                          <img src="images/logout_icon.svg" />
                          <Typography
                            style={{ cursor: "pointer" }}
                            variant="body2"
                            onClick={() => {
                              window.localStorage.removeItem("userToken");
                              window.location.reload();

                              history.push("/");
                            }}
                          >
                            Logout
                          </Typography>
                        </Box>
                      </div>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </div>
          )}
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box mb={1}>
              <img
                className={logoDrawer}
                src="images/fulilizo_logo_image.svg"
                alt="logo"
              />
            </Box>

            {getDrawerChoices()}
          </div>
        </Drawer>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Hidden lgUp>
            <IconButton
              color="#00e0b0"
              onClick={onMobileNavOpen}
              // style={{ padding: "0px" }}
            >
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>

          <Box display="flex" justifyContent="space-between">
            {femmecubatorLogo}
          </Box>
        </div>

        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#EC1F24", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };

  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderBottom: "3px solid #FF7F26",
          position: "fixed",
        }}
      >
        <Container maxWidth="xl" style={{ padding: "8px 24px" }}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
