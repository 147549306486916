import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/apiconfig/apiconfig";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.localStorage.getItem("userToken") ?? token;
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [timerActive, setTimerActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [topLeaderboardData, setListTopLeaderboardData] = useState([]);
  const [slideBannerdData, setSlideBannerData] = useState([]);
  console.log("isLoginisLogin: ", isLogin);
  const [slideSmallData, setslideSmallData] = useState([]);
  console.log("slideSmallData: ", slideSmallData);
  console.log("listAdvertiseByTypeData: ", topLeaderboardData);
  const history = useHistory();

  useEffect(() => {
    const storedRemainingTime = localStorage.getItem("remainingTime");
    if (storedRemainingTime !== null) {
      setRemainingTime(parseInt(storedRemainingTime, 10));
    }
    if (storedRemainingTime > 0) {
      setTimerActive(true);
    }
    if (timerActive && remainingTime > 0) {
      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = prevTime - 1;

          localStorage.setItem("remainingTime", newTime.toString());

          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (remainingTime === 0) {
      setTimerActive(false);
      setExpiredOTP(true);
      localStorage.removeItem("remainingTime");
    } else if (remainingTime) {
    }
  }, [remainingTime, timerActive]);

  const getProfileHandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getProfile,
        headers: {
          token: token,
        },
      });

      if (res?.data?.responseCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      if (
        error?.response?.data?.responseCode === 500 ||
        error?.response?.data?.responseMessage === "jwt malformed"
      ) {
        toast.error("Session Expired Please Login Again");
        localStorage.removeItem("userToken");
        setIsLogin(false);
        history.push("/");
        // window.location.href = "/login";
      }
    }
  };
  const topLeaderboard = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listAdvertiseByType,
        params: {
          advertisementType: "TOPLEADERBOARD",
        },
      });

      if (res?.data?.responseCode === 200) {
        setListTopLeaderboardData(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log("Error while fetching Ads of topLeaderboard", error);
      // if (error?.response) {
      //   toast.error(error.res?.data?.responseMessage);
      // }
    }
  };
  const slideBannerd = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listAdvertiseByType,
        params: {
          advertisementType: "SLIDEBANNER",
        },
      });

      if (res?.data?.responseCode === 200) {
        setSlideBannerData(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log("Error while fetching Ads of slideBannerd", slideBannerd);
      // if (error?.response) {
      //   toast.error(error.res?.data?.responseMessage);
      // }
    }
  };
  const slideSmall = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listAdvertiseByType,
        params: {
          advertisementType: "SLIDESMALL",
        },
      });

      if (res?.data?.responseCode === 200) {
        setslideSmallData(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log("Error while fetching Ads of slideSmall", slideSmall);
      // if (error?.response) {
      //   toast.error(error.res?.data?.responseMessage);
      // }
    }
  };

  useEffect(() => {
    let token = window.localStorage.getItem("userToken");
    if (token) {
      getProfileHandler(token);
    }
  }, [window.localStorage.getItem("userToken")]);
  useEffect(() => {
    topLeaderboard();
    slideBannerd();
    slideSmall();
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    setTimerActive,
    expiredOTP,
    timerActive,
    setTimerActive,
    setRemainingTime,
    remainingTime,
    topLeaderboardData,
    slideBannerdData,
    slideSmallData,
    // setIsLogin,
    getProfileHandler: getProfileHandler,
    // listAllMilestone: listAllMilestone,

    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
