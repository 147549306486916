import { HiMiniUserGroup } from "react-icons/hi2";
import { GrAnnounce } from "react-icons/gr";
import { TiDocumentText } from "react-icons/ti";
import { BsBagCheck } from "react-icons/bs";
import { IoSettingsSharp, IoWallet } from "react-icons/io5";
import { FaTools } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { PiChatTextThin } from "react-icons/pi";
import { TbGiftCard, TbLogout, TbNotes } from "react-icons/tb";
import { IoBagRemoveOutline } from "react-icons/io5";
import { LiaHandHoldingUsdSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";
import { CgProfile } from "react-icons/cg";
import { BiWalletAlt } from "react-icons/bi";
import { IoNotificationsSharp } from "react-icons/io5";

export const sections = [
  {
    items: [
      {
        title: "Dashboard",
        modules: "dashboard",
        icon: MdOutlineDashboard,
        href: "/dashboard",
      },
      {
        title: "CHAT",
        modules: "chat",
        icon: PiChatTextThin,
        href: "/chat",
      },
    ],
  },
];

export const sections1 = [
  {
    items: [
      {
        title: "My Media",
        modules: "MyMedia",
        icon: IoWallet,
        href: "/media-listing",
        subRoutes: [
          { title: "Add Media", href: "/add-media" },
          { title: "View Media", href: "/view-list-media" },
        ],
      },
    ],
  },
];

export const sections2 = [
  {
    items: [
      {
        title: "Work List",
        modules: "Work List",
        icon: TbNotes,
        href: "/work-list",
      },

      {
        title: "My Job List",
        modules: "My Job List",
        icon: IoBagRemoveOutline,
        href: "/job-list",
      },
      {
        title: "COMPLETED JOB",
        modules: "COMPLETED JOB",
        icon: BsBagCheck,
        href: "/completed-job",
      },
      {
        title: "Project offered",
        modules: "Project offered",
        icon: TiDocumentText,
        href: "/project-offered",
      },
      {
        title: "REVENUE & COMMISSION",
        modules: "REVENUE & COMMISSION",
        icon: LiaHandHoldingUsdSolid,
        href: "/revenue-commission",
      },
    ],
  },
];

export const sections3 = [
  {
    items: [
      {
        title: "ADVERTISEMENT",
        modules: "Advertisement",
        icon: GrAnnounce,
        href: "/advertisement",
        subRoutes: [
          {
            title: "View Advertisement",
            href: "/view-advertisement",
          },
          {
            title: "Edit Advertisement",
            href: "/edit-advertisement",
          },
        ],
      },
      {
        title: "PROJECT LIST",
        modules: "Project List",
        icon: CgNotes,
        href: "/project-list",
      },
    ],
  },
];

export const sections4 = [
  {
    items: [
      {
        title: "RECEVIED PAYMENT",
        modules: "RECEVIED PAYMENT",
        icon: GrAnnounce,
        href: "/order-details",
      },
      {
        title: "PAID PAYMENT",
        modules: "PAID PAYMENT",
        icon: CgNotes,
        href: "/transaction-payment",
      },
    ],
  },
];

export const sections5 = [
  {
    items: [
      {
        title: "VIEW PROFILE",
        modules: "View Profile",
        icon: CgProfile,
        href: "/view-profile",
      },
      {
        title: "MY WALLET",
        modules: "My Wallet",
        icon: BiWalletAlt,
        href: "/my-wallet",
      },
      {
        title: "NOTIFICATION",
        modules: "notification",
        icon: IoNotificationsSharp,
        href: "/notification",
      },

      {
        title: "AFFILIATED MEMBERS",
        modules: "Affiliated Members",
        icon: HiMiniUserGroup,
        href: "affiliatedmembers",
      },

      {
        title: "REWARD",
        modules: "Reward",
        icon: TbGiftCard,
        href: "rewards",
      },
      {
        title: "SETTINGS",
        modules: "Settings",
        icon: IoSettingsSharp,
        href: "change-password",
      },
      {
        title: "LOGOUT",
        modules: "Logout",
        icon: TbLogout,
        href: "logout",
      },
    ],
  },
];
export const sections6 = [
  {
    items: [
      {
        title: "CREATOR TOOLS",
        modules: "CREATOR TOOLS",
        icon: FaTools,
        href: "/dashboard-creator-studio",
      },
    ],
  },
];
export const sections7 = [
  {
    items: [
      {
        title: "STUDIO TOOLS",
        modules: "CREATOR TOOLS",
        icon: FaTools,
        href: "/dashboard-studio-studio",
      },
    ],
  },
];
