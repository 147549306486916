import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Banner")),
  },

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login/Login")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/About")),
  },

  {
    exact: true,
    path: "/register",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/SignUP/Index")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ForgotPassword/ForgotPassword")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/ResetPassword/ResetPassword")
    ),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/VerifyOTP/VerifyOTP")),
  },

  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Notifications/FullNotification")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-media",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-members",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/SignUP/AddMembers")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-media",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/EditMedia")),
  },
  {
    exact: true,
    guard: true,
    path: "/order-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/OrderDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/project-offered",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProjectOffered/ProjectOffered")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/project-offered-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProjectOffered/ProjectViewActions")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/completed-job",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/JobDetails/CompletedJobList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/job-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/JobDetails/JobDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TransactionPayment/TransactionPaymentList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Advertisement/Advertisement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Advertisement/AdvertisementView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Advertisement/EditAdvertisement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/advertisement-transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Advertisement/TransactionB")),
  },
  {
    exact: true,
    guard: true,
    path: "/create-ads",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Advertisement/CreateAds")),
  },
  {
    exact: true,
    guard: true,
    path: "/revenue-commission",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/RevenueCommission/RevenueCommission")
    ),
  },
  {
    exact: true,
    path: "/project-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectList/ProjectList")),
  },
  {
    exact: true,
    guard: true,
    path: "/create-project",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectList/CreateProject")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-project",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectList/ViewProject")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-project",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectList/EditProject")),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-po",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProjectList/TransactionPo")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/ViewProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-favourite",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/FavouriteMedias")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-later",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/ViewLaters")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Wallet/MyWallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-sp-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/ViewSpProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/edit-sp-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/EditSpProfile")),
  },
  {
    exact: true,
    // guard: true,
    path: "/media-center",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MediaCenter/MediaCenter")),
  },
  {
    exact: true,
    guard: true,
    path: "/feedbacks",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/FeedBack")),
  },
  {
    exact: true,
    guard: true,
    path: "/affiliatedmembers",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AffiliatedMembers/AffiliatedMembers")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-affiliatedmembers",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AffiliatedMembers/ViewAffiliatedMembers")
    ),
  },
  {
    exact: true,
    path: "/edit-affiliatedmembers",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AffiliatedMembers/EditAffiliatedMembers")
    ),
  },
  {
    exact: true,
    path: "/add-affiliatedmembers",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AffiliatedMembers/AddAffiliatedMember")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/ChangePassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/rewards",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Rewards/Rewards")),
  },
  {
    exact: true,
    path: "/explore-media",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MediaCenter/ExploreMedia")),
  },
  {
    exact: true,
    // guard: true,
    path: "/view-media",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MediaCenter/ViewMedia")),
  },
  {
    exact: true,
    // guard: true,
    path: "/services",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Services")),
  },

  {
    exact: true,
    // guard: true,
    path: "/creators",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Creators/Index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/creator-studio",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/Index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/dashboard-creator-studio",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/DashboardCreatorTools")),
  },
  {
    exact: true,
    // guard: true,
    path: "/dashboard-studio-studio",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/DashboardStudioTool")),
  },
  {
    exact: true,
    // guard: true,
    path: "/Image-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/ImageEdit")),
  },

  {
    exact: true,
    // guard: true,
    path: "/video-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/VedioEdit")),
  },
  {
    exact: true,
    // guard: true,
    path: "/audio-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/AudioEdit")),
  },
  {
    exact: true,
    // guard: true,
    path: "/video-recorder",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/VideoRecorder")),
  },
  {
    exact: true,
    // guard: true,
    path: "/voice-recorder",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/VoiceRecorder")),
  },
  {
    exact: true,
    // guard: true,
    path: "/art-design",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/ArtAndDrawing")),
  },
  {
    exact: true,
    // guard: true,
    path: "/qr-scanner",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CreatorStudio/QrScanner")),
  },
  {
    exact: true,
    // guard: true,
    path: "/charges",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Charges/Index")),
  },

  {
    exact: true,
    guard: true,
    path: "/chat",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Chat/Chat")),
  },
  {
    exact: true,
    guard: true,
    path: "/work-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/WorkList")),
  },
  {
    exact: true,
    guard: true,
    path: "/assign-job",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/AssignJob")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-pending-work",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/ViewWorkPending")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-accepted-work",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/ViewWorkAccepted")),
  },
  {
    exact: true,
    // guard: true
    path: "/subscription-plans",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Subscription/SubscriptionPlans")
    ),
  },

  {
    exact: true,
    // guard: true,
    path: "/demo",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Demo/Demo")),
  },

  {
    exact: true,
    // guard: true,
    path: "/team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Teams/Team")),
  },

  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/ContactUs")),
  },

  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/PrivacyPolicy")),
  },

  {
    exact: true,
    path: "/terms-and-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/TermsAndConditions")),
  },

  {
    exact: true,
    guard: true,
    path: "/view-progress-work",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/ViewProgressWork")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-completed-work",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/ViewCompletedWork")),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-payment",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/TransactionPayment")),
  },
  {
    exact: true,
    guard: true,
    path: "/job-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/JobList")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-job-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/RequestedJobView")),
  },
  {
    exact: true,
    guard: true,
    path: "/request-job-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/JobRequestList")),
  },
  {
    exact: true,
    guard: true,
    path: "/request-job-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/ViewJob")),
  },
  {
    exact: true,
    guard: true,
    path: "/accepted-job-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/JobViewAccepted")),
  },
  {
    exact: true,
    guard: true,
    path: "/after-milestone-job-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/AfterMilestoneJobView")),
  },

  {
    exact: true,
    guard: true,
    path: "/media-listing",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/Podcast/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-list-media",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/Podcast/ViewMedia")),
  },

  {
    exact: true,
    guard: true,
    path: "/job-view-completed",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Jobs/JobViewCompleted")),
  },

  {
    exact: true,
    guard: true,
    path: "/add-another-member",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/SignUP/Organization/AddAnotherMember")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/media-no-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Media/Podcast/NoListMedia")),
  },
  {
    exact: true,
    path: "/webvideo",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webvideo")),
  },
  {
    exact: true,
    path: "/webvoice",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/VideoCall")),
  },
  {
    exact: true,
    path: "/webvoice-test",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webaudio")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
