import { Link } from "@material-ui/core";
import React from "react";
import store from "src/store";

export const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  elevation: 0,
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

// export const localeFunction = (key) => {
//   let str = store.getState("auth");
//   let langCode = str.auth.langCode;
//   switch (langCode) {
//     case "en":
//       return require(`src/utils/lang/en`)[key];
//     default:
//       // return require(`src/utils/lang/${langCode}`)[key];
//       return require(`src/utils/lang/en`)[key];
//   }
// };
// export const localeFunction = (key) => {
//   // Ensure that store.getState("auth") returns a valid object
//   let str = store.getState("auth");

//   // Check if str and str.auth exist before accessing langCode
//   if (str && str.auth && str.auth.langCode) {
//     let langCode = str.auth.langCode;

//     switch (langCode) {
//       case "en":
//         return require(`src/utils/lang/en`)[key];
//       default:
//         return require(`src/utils/lang/${langCode}`)[key];
//     }
//   } else {
//     // Handle the case where auth or langCode is missing
//     console.error("Auth object or langCode is missing.");
//     // You may return a default language or an error message here
//     return `Missing language information for key: ${key}`;
//   }
// };
export const localeFunction = (key) => {
  return require(`src/utils/lang/en`)[key];
};

export const Tabledata = [
  {
    srNo: 1,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 2,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 3,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 4,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 5,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 6,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 7,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
  {
    srNo: 8,
    mediaName: "Aina Za Umiliki Wa Biashara",
    userName: "Abhinav",
    orderedDateTime: "Jul 4, 2023,6:22:32 PM",
    paidAmount: "10",
    downloadedStatus: "YES",
  },
];

export const chatData = [
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson",
    date: "Nov 26",
    msg: "I am intrested in buy your product.",
  },
];

export const senderData = [
  {
    msg: "It is a long established fact  will be looking at its layout.",
  },
  {
    msg: "It is a long established fact  will be looking at its layout.",
  },
];

export const ceateGroupData = [
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Consumer)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Partner)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
  {
    image: <img src="images/ChatDP.png" alt="DP" />,
    name: "Tony Johnson ",
    profession: "(Service Provider)",
  },
];

export const workListData = [
  {
    no: 1,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "CleanUp",
    price: 100,
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 2,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 3,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Accepted",
    action: "VIEW",
  },
  {
    no: 4,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In Progress",
    action: "VIEW",
  },
  {
    no: 5,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 6,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
  {
    no: 7,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 8,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
  {
    no: 9,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "FREE",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 10,
    provider: "Aina Za Umiliki Wa Biashara",
    type: "NIBI Country",
    price: "PAID",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Published",
    action: "VIEW",
  },
];

// export const transactionData = [
//   {
//     no: 1,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 2,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Service",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 3,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 4,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 5,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Service",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 6,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 7,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Service",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 8,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 9,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
//   {
//     no: 10,
//     provider: "TTCNI022000800594",
//     username: "Abhinav",
//     date: "24th July, 2023 13:09",
//     type: "Podcast",
//     paidAmount: "10",
//     action: "VIEW",
//   },
// ];

export const jacksonData = [
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
  {
    image: <img src="images/Professor.png" alt="professor" />,
    name: "Michael Jackson",
    rating: 4.5,
    starImage: <img src="images/ratingstar.png" alt="rating" />,
    action: "View",
  },
];

export const pendingData = [
  {
    title: "Service Provider:",
    info: "Arvind",
    view: <Link>View Details</Link>,
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status :",
    info: "Pending",
  },
  {
    title: "Questions and special instructions (if any):",
    info: "creatives specialized in the development of audio and video content.",
  },
  {
    title: "Required Skills :",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    title: "Attachment:",
    info: "https://docs.google.com",
  },
];

export const viewWorkCompletedData = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "User Type :",
    info: "Consumer",
  },
  {
    title: "Service Type",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Remark :",
    info: "They earn from taking content creation jobs from Fululizo, upon job.",
  },
  {
    title: "File :",
    info: "Aina Za Umiliki Wa Biashara",
    image: <img src="images/imageMP4.png" alt="mp4" />,
  },
];

export const completedData = [
  {
    title: "Service Provider:",
    info: "Arvind",
    view: <Link>View Details</Link>,
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status :",
    info: "Pending",
  },
];

export const workDetailsData = [
  {
    title: "Service Provider:",
    info: "Arvind",
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const contractData = [
  {
    title: "Contract Fees:",
    info: "100 Kenya shiling",
  },
  {
    title: "Contract Duration:",
    info: `1 Month `,
    info2: "(after accepting the contract from both the parties.)",
  },
];
export const servicesData = [
  {
    serviceImage: "images/services_first.svg",
    serviceTitle: "24 Millions Business",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_first_back3.png",
  },
  {
    serviceImage: "images/services_second.svg",
    serviceTitle: "1,200 Service Offered",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_second_back.png",
  },
  {
    serviceImage: "images/services_third.svg",
    serviceTitle: "05 Millions Visitors",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_third_back.png",
  },
  {
    serviceImage: "images/services_fourth.svg",
    serviceTitle: "5+ Development Tool",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    backgroundImage: "images/services_fourth_back.png",
  },
];

export const servicesTypeData = [
  {
    serviceImage: "images/service_type1.svg",
    serviceTitle: "CLEAN-UP",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type2.svg",
    serviceTitle: "SCRIPTING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type3.svg",
    serviceTitle: "EDITING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type4.svg",
    serviceTitle: "RE-ORGANISE",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type5.svg",
    serviceTitle: "TRANSCRIPTION",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type6.svg",
    serviceTitle: "VOICE-OVER",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type7.svg",
    serviceTitle: "TRIMMING",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
  {
    serviceImage: "images/service_type8.svg",
    serviceTitle: "TRANSLATION",
    serviceText:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
  },
];

export const creatorsData = [
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
  {
    creatorImage: "images/creator_img.svg",
    creatorName: "Ronald Ramirez",
    creatorRole: "Founder Of Org.",
    decription:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.",
  },
];

export const chargesData = [
  {
    icon: "images/charges_icon.svg",
    title: "CLEAN-UP",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "SCRIPTING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "EDITING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "RE-ORGANISE",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },

  {
    icon: "images/charges_icon.svg",
    title: "CLEAN-UP",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "SCRIPTING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "EDITING",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
  {
    icon: "images/charges_icon.svg",
    title: "RE-ORGANISE",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.",
    price: "KES 100 per service",
  },
];

export const subscriptionPlansData = [
  {
    icon: "images/subscription_image_first.svg",
    title: "Self-Service Dashboard",
    description: `We provide you with online dashboard login to manage all your media actions \n
    You get access to experts on-demand basis
    `,

    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],

    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],
    price: "22,500",
    payble: "Payable every month",
  },

  {
    icon: "images/subscription_image_second.svg",
    title: "Managed Dashboard",
    description: `We open an account for you and dedicate a team to action all your requests. We provide you with online dashboard login to provide your instructions to us
    `,

    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],
    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],

    price: "22,500",
    payble: "Payable every month",
  },

  {
    icon: "images/subscription_image_third.svg",
    title: "Premium Dashboard",
    description: `We provide you with dedicated USSD Dial Code in addition to either a self-service or managed dashboard
    `,

    radioButtons: [
      { label: "SELF - SERVICE", checked: true },
      { label: "MANAGED", checked: false },
    ],
    addFunctionCheckboxes: [
      { label: "CREATE & EDIT MEDIA" },
      { label: " STUDIO TOOLS" },
      { label: "  TRAINER TOOLS" },
      { label: "  DISTRIBUTE MEDIA" },
      { label: " MULTI-USER FUNCTIION" },
    ],

    chooseFeeCycleCheckboxes: [
      { label: "MONTHLY PLAN " },
      { label: " ANNUAL PLAN" },
    ],

    price: "22,500",
    payble: "Payable every month",
  },
];

export const jobDetailsData = [
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

export const contractDetailsData = [
  {
    title: "Contract Fees :",
    info: "Content Creator",
  },
  {
    title: "Contract Duration:",
    info: "1 month",
    info2: " (after accepting the contract from both the parties.)",
  },
];

//used
export const workViewData = [
  {
    title: "Service Provider:",
    info: "Arvind",
  },
  {
    title: "Service Type:",
    info: "Content Creator",
  },
  {
    title: "Payment Status:",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
  {
    title: "Order Status:",
    info: "Completed",
  },
  {
    title: "Remark :",
    info: "They earn from taking content creation jobs from Fululizo, upon job.",
  },
  {
    title: "File:",
    info: "Aina Za Umiliki Wa Biashara",
    image: <img src="images/imageMP4.png" alt="mp4" />,
  },
];

//used
export const transactionPaymentData = [
  {
    title: "Transaction ID :",
    info: "TTCNI022000800594",
  },
  {
    title: "Transaction Amount :",
    info: "100 KES",
  },
  {
    title: "Transaction Date & Time :",
    info: "100 KES",
  },
];

//used
export const orderTypeData = [
  {
    title: "Order Type :",
    info: "Podcast",
  },
  {
    title: "Media Name:",
    info: "Aina Za Umiliki Wa Biashara",
  },
  {
    title: "Media Price :",
    info: "100 KES",
  },
  {
    title: "User Name :",
    info: "Abhinav",
  },
];

//used
export const orderTypeData2 = [
  {
    title: "Order Type :",
    info: "Podcast",
  },
  {
    title: "Media Name:",
    info: "Aina Za Umiliki Wa Biashara",
  },
  {
    title: "Media Price :",
    info: "100 KES",
  },
  {
    title: "User Name :",
    info: "Abhinav",
  },
  {
    title: "Service Provider Name:",
    info: "Abhinav",
  },
];

//used
export const jobListData = [
  {
    no: 1,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In progress",
    action: "VIEW",
  },
  {
    no: 2,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 3,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Accepted",
    action: "VIEW",
  },
  {
    no: 4,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In progress",
    action: "VIEW",
  },
  {
    no: 5,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 6,
    userName: "Subashini",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 7,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 8,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 9,
    userName: "Subashini",
    userType: "Consumer",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 10,
    userName: "Abhinav",
    userType: "Consumer",
    serviceType: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
];

//used
export const projectOfferedData = [
  {
    no: 1,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 2,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "In Process",
    action: "VIEW",
  },
  {
    no: 3,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Commented",
    action: "VIEW",
  },
  {
    no: 4,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 5,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Delivered",
    action: "VIEW",
  },
  {
    no: 6,
    projectName: "Video Editing",
    userType: "Brand",
    serviceType: "CleanUp",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Completed",
    action: "VIEW",
  },
  {
    no: 7,
    projectName: "Content Writing",

    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 8,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 9,
    projectName: "Content Writing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
  {
    no: 10,
    projectName: "Video Editing",
    date: "Jul 4, 2023,6:22:32 PM",
    status: "Pending",
    action: "VIEW",
  },
];

//used
export const viewJobDetailsData = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "User Type :",
    info: "Consumer",
  },
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

//used
export const viewacceptedJobDetails = [
  {
    title: "User Name :",
    info: "Arvind",
  },
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Contract Price :",
    info: "100 Kenya Shiling",
  },
  {
    title: "Payment Status :",
    info: "Paid",
  },
  {
    title: "Description:",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

//used
export const contactData = [
  {
    image: <img src="images/contact_message.svg" alt="call" />,
    name: "connect@fululizo.com",
    info: "",
  },
  {
    image: <img src="images/contact_phone.svg" alt="mail" />,
    name: "Tel: +254 797 771771",
    info: "",
  },
  {
    image: <img src="images/contact_location.svg" alt="location" />,
    name: "INTEGRAL MEDIA LIMITED",
    info: "Jadala Place, 1st FloorNgong Lane, Off Ngong Road",
  },
];

//used
export const createContractData = [
  {
    title: "Service Type :",
    info: "Content Creator",
  },
  {
    title: "Description :",
    info: "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers",
  },
];

//used
export const filteredPodcast = [
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },

  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },

  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
];

//used
export const relatedPostData = [
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_second.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_third.png",
  },
  {
    postedDate: "Posted Date :- 02May,2023",
    artistName: "Aina Za Umiliki Wa Biashara",
    genre: "Mental heath & wellness",
    description:
      "Sit risus id placerat morbi. Velit pellentesque sed duis id aliquam quis leo congue.........",
    img: "images/card_image_first.png",
  },
];

//used
export const FeedbackCount = [
  {
    Title: "Total Review",
    count: "10.8K",
    type: "Review",
    image: "images/starGroup.svg",
  },
  {
    Title: "Average Rating",
    count: "4.5",
    star: "1",
    type: "Rating",
    image: "images/rating.svg",
  },
  {
    Title: "Total Complete Job",
    count: "77",
    type: "Job",
    image: "images/job.svg",
  },
];

//used
export const FeedbackRating = [
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
  {
    image: "images/profileImg.svg",
    name: "Amol Yadav",
    type: "Consumer",
    star: "4.5",
    date: "24-09-202",
    description:
      "creatives specialized in the development of audio and video content. They earn from taking content creation jobs from Fululizo, upon job orders places on Fululizo by content owners, partner organisations, brands and consumers.",
  },
];


