import React, { useEffect, useState } from "react";
import {
  Fade,
  Modal,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import { Link, useHistory, useLocation } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { Backdrop } from "@material-ui/core";
import { IoMdCloseCircleOutline } from "react-icons/io";
import ApiConfig from "src/apiconfig/apiconfig";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  mainNotificationBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 450,
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    maxHeight: "55vh",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  notificationBox: {
    "& .childBox": {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "10px",
      padding: "10px",
      background: "#80808029",
      marginBottom: "5px",
    },
    "& .messageDetail": {
      width: "100%",
    },
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    borderBottom: "1px solid #ccc",
    paddingBottom: theme.spacing(1),
  },
  noNotifications: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

const Notification = ({ open, setOpen, setCountNotification }) => {
  const classes = useStyles();
  const history = useHistory();
  const [notificationList, setNotificationList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const listNotification = async () => {
    try {
      setIsDataLoading(true);
      const res = await axios({
        method: "POST",
        url: `${ApiConfig.listNotification}?isNew=true`,
        headers: {
          token: localStorage.getItem("userToken"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setNotificationList(res?.data?.result?.docs);
        setIsDataLoading(false);
      }
    } catch (error) {
      setIsDataLoading(false);
      if (error.response) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };

  const allNotificationsCount = async () => {
    try {
      setIsDataLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.allNotificationsCount,
        headers: {
          token: localStorage.getItem("userToken"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setCountNotification(res?.data?.result[0]?.count);
      }
    } catch (error) {
      setIsDataLoading(false);
      if (error.response) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };

  const readAllNotificationAtSeeAll = async () => {
    try {
      setIsDataLoading(true);
      const res = await axios({
        method: "PUT",
        url: ApiConfig.readNotification,
        headers: {
          token: localStorage.getItem("userToken"),
        },
      });
      if (res?.data?.responseCode === 200) {
        // Do something if needed
      }
    } catch (error) {
      setIsDataLoading(false);
      if (error.response) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };

  const handleClickSeeAll = async () => {
    await readAllNotificationAtSeeAll();
    history.push({
      pathname: "/notification",
    });
  };

  useEffect(() => {
    listNotification();
    allNotificationsCount();
  }, []);

  const handleNotificationRead = async (notificationId) => {
    try {
      const response = await axios({
        method: "PUT",
        url: `${ApiConfig.readSingleNotification}?notificationId=${notificationId}`,
        headers: {
          token: localStorage.getItem("userToken"),
        },
      });
      if (response?.data?.responseCode === 200) {
        toast.success(response?.data?.responseMessage);
        listNotification();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.mainNotificationBox}>
          <Box className={classes.modalHeader}>
            <Typography variant="h4">Notification</Typography>
            {notificationList.length > 0 && (
              <Button
                onClick={handleClickSeeAll}
                style={{ textDecoration: "underline" }}
              >
                See All
              </Button>
            )}
          </Box>
          <List>
            {isDataLoading ? (
              <ListItem>
                <ListItemText primary="Loading..." />
              </ListItem>
            ) : notificationList.length === 0 ? (
              <ListItem>
                <ListItemText
                  primary="No notifications found."
                  className={classes.noNotifications}
                />
              </ListItem>
            ) : (
              notificationList.map((data) => (
                <Box className={classes.notificationBox} key={data?._id}>
                  <Box className="childBox">
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <Box className="messageDetail">
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "500" }}
                          >
                            {data?.title || "N/A"}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: "#4a4545" }}
                          >
                            {data?.body || "N/A"}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          style={{ color: "#4a4545" }}
                        >
                          {moment(data?.currentTime).format("lll")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            textAlign: "end",
                            textDecoration: "underline",
                          }}
                          onClick={() => handleNotificationRead(data?._id)}
                        >
                          Mark as Read
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#4a4545",
                            marginTop: "15px",
                            textAlign: "end",
                          }}
                        >
                          {data?.currentDay}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))
            )}
          </List>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Notification;
