import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory } from "react-router-dom";
import { localeFunction } from "src/utils/index";

const useStyles = makeStyles((theme) => ({
  acceptBtn: {
    fontSize: "14px",
    color: "green",
  },
  rejectBtn: {
    fontSize: "14px",
    color: "red",
  },
}));

export default function NotificationAlertPopUp({
  open,
  setOpen,
  data,
  notification,
}) {
  console.log("🚀 ~ notification:", notification);
  console.log("🚀 ~ notificationdata:", data);
  console.log("🚀 ~ setOpen:", setOpen);
  console.log("🚀 ~ open:", open);
  const [snackPack, setSnackPack] = useState([]);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const historyComp = useHistory();

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const navigateToWebVoice = () => {
    historyComp.push({
      pathname: "/webvoice",
      state: {
        token: data?.token,
        channel: data?.channelId,
      },
    });
  };

  const classes = useStyles();
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        // message={notification ? notification.title : ""}
        action={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">
              {notification ? notification.title : ""}
            </Typography>{" "}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              style={{ maxWidth: "380px" }}
            >
              <img
                src={
                  data?.profilePic
                    ? data?.profilePic
                    : "images/user_placeholder.jpg"
                }
                style={{ width: "60px", height: "60px", marginRight: "10px" }}
              />
              <Typography variant="body1">
                {notification ? notification.body : ""}
              </Typography>
              {!data?.callType && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    className={classes.rejectBtn}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                    {localeFunction("my_Post_text_4")}
                  </IconButton>
                </div>
              )}
            </Box>
            {data?.callType && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  type="button"
                  className={classes.acceptBtn}
                  onClick={() => {
                    if (data.callType === "Video") {
                      historyComp.push({
                        pathname: "/webvideo",
                        state: {
                          token: data?.token,
                          channel: data?.channelId,
                        },
                      });
                      handleClose();
                    } else {
                      navigateToWebVoice();
                      handleClose();
                    }
                  }}
                >
                  <DoneIcon />
                  {localeFunction("my_Post_text_5")}
                  {/* {"Accept"} */}
                </IconButton>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.rejectBtn}
                  onClick={handleClose}
                >
                  <CloseIcon />
                  {localeFunction("my_Post_text_6")}
                  {/* {"Decline"} */}
                </IconButton>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
  console.log("🚀 ~ data:", data);
}
